 <template>
  <div class="template-management" ref="templateManagement">
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <!-- <el-form-item label="所属行业：">
          <el-input v-model.trim="form.businessId" placeholder="请输入所属行业"></el-input>
        </el-form-item>-->
        <!-- <el-form-item label="状态：">
          <el-select v-model="form.status" clearable placeholder="请选择状态">
            <el-option label="正常" :value="0"></el-option>
            <el-option label="锁定" :value="1"></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" size="small" @click="onAddTemplate">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="模板名称"></el-table-column>
      <!-- <el-table-column label="字段模板内容">
        <template slot-scope="scope">{{scope.row.content | filterContent}}</template>
      </el-table-column>-->
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column prop="status" label="是否锁定模板状态">
        <template slot-scope="scope">
          <el-switch
            @change="changeStatus($event,scope.row)"
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit-outline"
            type="text"
            size="small"
            sort="primary"
            @click="onEditTemplate(scope.row)"
          >编辑</el-button>
          <el-button
            icon="el-icon-delete"
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteTemplate(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="75%">
      <div>
        <div class="body"></div>
        <addTemplate ref="addTemplate" :templateId="templateId" @onDialogClose="onDialogClose" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSave"
        >{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

  <script>
import addTemplate from "./components/addTemplate.vue";
import {
  getFieldsPage,
  updateFieldStatus,
  deleteFieldsById
} from "@/api/lib/api.js";
export default {
  name: "templateManagement",
  components: {
    addTemplate
  },
  data () {
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      form: {
        status: null,
        current: 1,
        size: 10
      },
      multipleSelection: [],
      title: "",
      templateId: null,
      dialogVisible: false
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.templateManagement.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16 - 35;
    },
    // 点击查询
    onSearch () {
      this.form.current = 1;
      this.getTemplateListByField();
    },
    //获取数据
    getTemplateListByField () {
      getFieldsPage(this.form).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          if (!res.data.records.length && this.form.current !== 1) {
            //如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
            self.form.current -= 1;
            self.getTemplateListByField();
          }
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      })
    },
    //新增
    onAddTemplate () {
      this.title = "新增流程模板";
      this.templateId = '';

      this.dialogVisible = true;
    },
    onEditTemplate (row) {
      this.title = "编辑流程模板";
      this.templateId = row.id;
      this.dialogVisible = true;
    },
    // 修改品牌状态
    changeStatus (e, row) {
      let message = e === 1 ? "锁定" : "启用";
      updateFieldStatus({ id: row.id, status: String(e) }).then((res) => {
        if (res.code === 0) {
          this.$message.success(`${message}成功`);
        } else {
          this.$message.error(`${message}失败`);
          row.status = e === 1 ? 0 : 1;
        }
      });
    },
    //删除流程模板
    onDeleteTemplate (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "确定删除流程模板 "),
          h("i", { style: "color: #059370" }, row.name),
          h("span", null, " ？")
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          deleteFieldsById(row.id).then(res => {
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.getTemplateListByField();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除"
          });
        });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.current = page;
      this.getTemplateListByField();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.size = size;
      this.getTemplateListByField();
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false;
      if (flag) {
        this.getTemplateListByField();
      }
    },
    onSave () {
      this.$refs.addTemplate.onSave();
    }
  },
  filters: {
    filterContent: function (arr) {
      let content = ''
      arr.forEach(element => {
        content.push(`${element.label}：${element.fieldName}`)
      });
      return content.join("|")
    }
  },
  created () {
    this.onSearch();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  }
};
</script>
<style lang="scss" scoped>
.template-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  .avatarImg {
    vertical-align: middle;
    border-radius: 50%;
  }
}

.theme-light {
  .template-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>