<template>
	<el-form
		:model="addForm"
		ref="addForm"
		label-width="82px"
		label-position="left"
		class="demo-form dialog-form"
	>
		<el-form-item label="模板名称：">
			<el-input v-model.trim="addForm.name" placeholder="请输入模板名称"></el-input>
		</el-form-item>
		<el-form-item label="所属行业：" prop="businessId">
			<el-select v-model="addForm.businessId"  placeholder="请选择所属行业">
				<el-option
					v-for="business in businessList"
					:label="business.name"
					:value="business.id"
					:key="business.id"
					:disabled="business.status!==0"
				></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="描述：">
			<el-input v-model.trim="addForm.description" type="textarea"></el-input>
		</el-form-item>
		<el-row :gutter="20" v-for="(template, index) in addForm.templates" :key="index">
            <el-col :span="7">
				<el-form-item
					label="标签名："
					:prop="'templates.' + index + '.label'"
					:rules="{
                    required: true, message: '标签名不能为空', trigger: 'blur'
                }"
				>
					<el-input :disabled="templateId!=''" v-model="template.label"></el-input>
				</el-form-item>
			</el-col>
			<el-col :span="7">
				<el-form-item
					label="字段名："
					:prop="'templates.' + index + '.fieldName'"
					:rules="{
                    required: true, message: '字段名不能为空', trigger: 'blur'
                }"
				>
					<el-input :disabled="templateId!=''" v-model="template.fieldName"></el-input>
				</el-form-item>
			</el-col>
            <el-col :span="8">
				<el-form-item
					label="	类型："
					:prop="'templates.' + index + '.fieldName'"
					:rules="{
                    required: true, message: '字段类型不能为空', trigger: 'blur'
                }"
				>
                    <el-select :disabled="templateId!=''" v-model="template.type" clearable placeholder="字段类型">
						<el-option v-for="(item,key) in fieldList" :key="key" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="2">
				<el-button @click.prevent="removeTemplate(template)">删除</el-button>
			</el-col>
		</el-row>
		<el-form-item>
			<el-button :disabled="templateId!=''" @click="addTemplate">新增字段</el-button>
		</el-form-item>
	</el-form>
</template>
<script>
import { checkRoleName, checkRoleCode } from "@/common/utils/index";
import { getDictsItemByType } from "@/api/lib/api.js";
import {
	insertFields,
	updateFields,
	getFieldsById,
	getBusinessPage,
} from "@/api/lib/api.js";

export default {
	components: {},
	props: {
		templateId: {
			type: [Number, String],
		},
	},
	data() {
		return {
			addForm: {
				templates: [
					{
                        fieldName:"",
						label: "",
                        type: "",
					},
				],
				businessId: "",
				description: "",
			},
			businessList: [],
			fieldList:[],
		};
	},
	methods: {
		// 新增/保存用户
		onSave() {
			this.$refs.addForm.validate((valid) => {
				//console.log("this.addForm----", this.addForm.templates);
				if (valid) {
					let data = {
						name:this.addForm.name,
						businessId: this.addForm.businessId,
						description: this.addForm.description,
						content: JSON.stringify(this.addForm.templates),
					};
					if (this.templateId) {
						data.id = this.addForm.id;
						updateFields(data).then((res) => {
							if (res.code === 0) {
								this.$message.success("修改成功");
							} else {
								this.$message.error(res.message);
							}
							this.$emit("onDialogClose", res.code === 0);
						});
					} else {
						insertFields(data).then((res) => {
							if (res.code === 0) {
								this.$message.success("新增成功");
							} else {
								this.$message.error(res.message);
							}
							this.$emit("onDialogClose", res.code === 0);
						});
					}
				}
			});
		},
		addTemplate() {
			this.addForm.templates.push({
				fieldName:"",
                label: "",
                type: "",
			});
		},
		removeTemplate(item) {
			var index = this.addForm.templates.indexOf(item);
			if (index !== -1) {
				this.addForm.templates.splice(index, 1);
			}
		},
		//获取行业列表
		getBusinessPage() {
			getBusinessPage().then((res) => {
				if (res.code === 0) {
					this.businessList = res.data.records;
				}
			});
		},
		//获取字段类型列表
		getFieldslist(){
			getDictsItemByType("filed_type").then((res)=>{
				if(res.code===0){
					this.fieldList=res.data
				}
			})
		}
	},
	created() {
		this.getBusinessPage();
		this.getFieldslist();
        //console.log(this.templateId);
		if (this.templateId) {
			getFieldsById(this.templateId).then((res) => {
				if (res.code === 0) {
                    this.addForm = { ...res.data };
                    //console.log(res.data.content);
                    if(res.data.content&&res.data.content.length>0){
                        this.addForm.templates=JSON.parse(res.data.content);
                        //console.log(this.templates);
                    }
				}
			});
		}
	},
	mounted() {},
};
</script>